<template>
  <router-view></router-view>
</template>
<script>
import { log } from "./util";
export default {
  data() {
    return {
      timer: null,
      gc: null,
    };
  },
  created() {
    this.gc = require("js-gc");
  },
  methods: {
    // 强制手动GC
    forceGC() {
      if (this.gc) {
        this.gc();
      }
    },
  },
  computed: {
    reLogin() {
      return this.$store.state.reLogin;
    },
  },
  watch: {
    reLogin(newValue) {
      if (newValue && this.$route.path !== "/") {
        this.$confirm("您的登录状态异常，请重新登录", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/");
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("hideReLogin");
          });
      }
    },
  },
  mounted() {
    if (
      !window.location.href.includes("/chenfeng/query_history/pc") &&
      !window.location.href.includes("/chenfeng/query_history/mobile")
    ) {
      this.$store.dispatch("asVendorStatementCount");
      this.$store.dispatch("asEmptyStatementOrderCount");
      this.$store.dispatch("asPendingBillsCount");
      this.$store.dispatch("asPendingUnfinishedCount");
    }

    this.timer = setInterval(() => {
      this.forceGC();
    }, 60 * 1000);

    log("Eztrans Admin", "info");
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss">
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  // 做到只设置一次，就可以使所有a标签的四种状态都和本身颜色保持一致，样式代码该怎么写
}

// .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label, .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content{
//   width: 100px;
// }

// .ant-descriptions-item-label .ant-descriptions-item-colon{
//   width: 120px;
// }
:focus {
  outline: 0;
}

body {
  overflow-x: auto;
  overflow-y: hidden;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #515a6e;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}

.el-radio--mini.is-bordered {
  margin-right: 2px;
}

.el-menu-item {
  background-color: #373e4f !important;
}

.el-menu-item.is-active {
  background-color: #2d8cf0 !important;
}

.editOrder_modal .el-dialog__body {
  padding-top: 0 !important;
  padding-bottom: 0px !important;
}

.editOrder_modal .el-dialog__header {
  padding-top: 0 !important;
  // padding-bottom: 0 !important;
}

.cursor_pointer {
  cursor: pointer;
}

.break_word {
  word-break: break-word;
  overflow-wrap: break-word;
}

// !识别换行符
.wrap_code {
  white-space: pre-wrap;
}

.cursor {
  cursor: pointer;
}

.over {
  overflow: hidden;
  zoom: 1;
}

.left {
  float: left;
  height: 100%;
}

.right {
  float: right;
}

.flex {
  display: flex;
}

.clearFix:after {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  line-height: 0px;
  clear: both;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cursor {
  cursor: pointer;
}

.attention {
  width: 16px;
  line-height: 16px;
  font-size: 8px;
  text-align: center;
  background: rgb(193, 191, 191);
  border-radius: 50%;
  color: white;
  cursor: pointer;
}

.attention:hover {
  // background: rgba(100, 100, 100, 1);
  background: rgb(147, 146, 146);
}

.content_form_inline .el-form-item {
  margin-bottom: 8px !important;
}

//!修改斑马纹的颜色
.el-table .warning-row td {
  background: rgba(200, 10, 0, 0.3) !important;
}

.route-row {
  background-color: #eeeeee !important;
}

#order_table .el-table td {
  padding: 0 !important;
}

#order_table .el-table .cell,
.el-table--border td:first-child .cel {
  padding: 0 !important;
}

.order_popper.el-popper {
  margin-top: 0 !important;
}

.oms_card .ivu-card-head {
  padding: 0 !important;
  // height: 40px !important;
}

.excel_modal .el-dialog__header {
  padding: 0 !important;
}

.excel_modal .el-dialog__body {
  padding-top: 0 !important;
}

.notification_custom {
  cursor: pointer;
}

.create_doc_modal .el-dialog__body {
  padding: 30px 20px 0 20px;
}

#search_user .el-table__header-wrapper .cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.processormetapad .el-form-item__label {
  padding-right: 2px;
}

::-webkit-input-placeholder {
  color: #bbb;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #bbb;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #bbb;
}

:-ms-input-placeholder {
  color: #bbb;
}

.expend_node_info .el-table__expand-icon {
  display: none;
}

.descriptioneztrans .ant-descriptions-item-label {
  width: 165px;
}

#xiangkankan .ant-descriptions-item-label {
  width: 265px;
}

.adw_dialog .editOrder_modal {
  overflow: hidden !important;
}

//date 一些特殊的属性  会删除所有边距
.date_input_width .el-input__inner {
  padding: 4px !important;
}

.tracking_dialog .el-dialog__body {
  padding-top: 6px !important;
}

.tracking_dialog {
  .trackingDialog {
    margin-bottom: 0 !important;
    height: calc(100vh - 20px) !important;
    overflow-y: scroll;
  }

  .el-dialog__header {
  }

  // margin-top: 20px !important;
  .el-dialog__body {
    //border-top: 1px solid #dcdfe6;
    //border-bottom: 1px solid #dcdfe6;
    // max-height: calc(100vh - 20px) !important;
    box-sizing: border-box !important;
    min-height: 100px;
    overflow-y: auto;
  }

  .el-dialog__footer {
    position: fixed;
    bottom: 0px;
    right: 10px;
    background: white;
    width: 100%;
    padding-bottom: 10px !important;
  }
}

.dia {
  .el-dialog__body {
    //border-top: 1px solid #dcdfe6;
    //border-bottom: 1px solid #dcdfe6;
    max-height: 470px !important;
    min-height: 100px;
    overflow-y: auto;
  }
}

.specialinput {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.editOrder_modal {
  overflow: hidden !important;
}

.noallselecttable .el-table__header .el-table-column--selection .el-checkbox {
  display: none;
}

.trackcard .ivu-card-head {
  padding: 4px 16px !important;
}

.trackcard .ivu-card-head p {
  margin-bottom: 0px !important;
}

.air_collapse .el-collapse-item__header {
  height: 100%;
}

.edit_price_modal .el-dialog.is-fullscreen {
  display: flex;
  flex-direction: column;

  .el-dialog__body {
    flex: 1;
    overflow: scroll;

    .edit_price_detail .ant-descriptions-row {
      .ant-descriptions-item-label.ant-descriptions-item-colon {
        width: 60px;
      }

      .ant-descriptions-item-content {
        &:first-of-type {
          width: 298px;
          height: 44px;
        }

        &:nth-of-type(2) {
          width: 209px;
          height: 44px;
        }

        &:nth-of-type(3) {
          width: 182px;
          height: 44px;
        }
      }
    }

    .edit_price_carrier {
      .ant-descriptions-item-label.ant-descriptions-item-colon {
        width: 100px;
      }

      .ant-descriptions-item-content {
        width: 303px;
        height: 44px;
      }
    }

    .edit_price_supplier {
      .ant-descriptions-item-content {
        width: 181px;
        height: 44px;
      }
    }

    .edit_price_rvf_rve {
      .ant-descriptions-item-content {
        width: 429px;
        height: 56px;
      }
    }
  }
}

.edit_price_table {
  .el-table__header-wrapper,
  .el-table__header {
    th {
      background: #f8f8f8;
    }
  }
}

// 分享订单链接
.shareModal {
  .input {
    height: 45px;

    input {
      height: 45px;
    }

    &.clipSuccess {
      input {
        border: 1px solid #34b834;
      }

      .el-input-group__append {
        background: #34b834;
        border: 1px solid #34b834;

        .clipLink {
          background: #34b834;
          border: 1px solid #34b834;
          color: #fff;
        }
      }
    }
  }
}

// 为了财务查询表格指定单元格能够撑满
.finaTable {
  .td {
    position: relative;
  }

  .cell {
    padding: 0 !important;
  }
}

.finaTable .el-table__expanded-cell {
  padding: 10px 20px !important;
}

.finaExpendTable {
  td {
    padding: 5px 0 !important;
  }

  .el-table__body-wrapper {
    overscroll-behavior-y: contain;
  }

  .cell {
    padding: 0px !important;
  }

  .el-table__body-wrapper .el-table__row td {
    position: relative;

    .fullPoint {
      cursor: pointer;
    }

    .fullCell {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      // overflow: scroll;
    }
  }
}

// 价格修改的inputNumber组件样式修改（隐藏原生上下箭头）
.inputNumber {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  input[type="number"] {
    -moz-appearance: textfield !important;
  }
}

// 财务报表中的表格ui修改
.financialTotalTable {
  .warning_row {
    color: red !important;
  }

  //   tbody tr:hover > td {
  //       background-color: transparent !important;
  //  }
  td,
  th {
    position: relative;

    .cell {
      position: static !important;
    }

    .cellLeftLine::before {
      content: "";
      width: 1px;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: red;
    }

    .cellBgRev {
      background: red;
    }
  }

  td {
    padding: 0 8px !important;
  }
}

// 月度账单表格的ui修改
.monthFinancialTotalTable {
  td,
  th {
    position: relative;

    .cell {
      position: static !important;
    }

    .cellBgRev,
    .cellBgCost,
    .cellBgGp {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .cellBgRev {
      background: #fff1f9 !important;
    }

    .cellBgCost {
      background: #f1ffff !important;
    }

    .cellBgGp {
      background: #fff6f1 !important;
    }
  }

  td {
    padding: 0 8px !important;
  }
}

//新建账单ui缩小
.inputContainer .widthTable .new-account-table td {
  padding: 0 !important;
}

.financialStateDialog .companytable td {
  // height: 30px !important;
  padding: 0 !important;
}

//缩小现舱表格宽度
.freight .el-table--enable-row-transition .el-table__body td .cell {
  padding: 0 3px !important;
  // background: red !important;
}

//报价单页面创建副本报价单变色
.componentsTable .change_row_color {
  background: rgb(252, 252, 165) !important;
}

// 财务报表月度报表日期范围选择ui调整
.el-picker-panel__body-wrapper {
  .el-month-table td .cell {
    // width: 36px !important;
  }
}

.flex_style .form_required .el-form-item__label::before {
  content: "*";
  margin-right: 3px;
  color: red;
}

// 月度账单的uiATD筛选ui调整
.monthStatement {
  .bold {
    .el-checkbox__label {
      font-weight: 700 !important;
    }
  }
}

//财务账单表格缩小
.darkBorderTable {
  border-color: #ccc !important;

  &:before {
    // 表格底部边框色
    background-color: #ccc !important;
  }

  &:after {
    // 表格右侧边框色
    background-color: #ccc !important;
  }

  tr {
    border-color: #ccc !important;

    th {
      padding: 6px !important;
      border-color: #ccc !important;
    }

    td {
      padding: 0 !important;
      border-color: #ccc !important;

      .newCell {
        min-height: 28px;
        padding: 0;
      }
    }
  }
}

//修改账单pdf的账户信息表格
.account_info_table {
  .el-table__header-wrapper th {
    padding: 3px !important;
  }

  .el-table__body-wrapper {
    td {
      padding: 1px !important;
    }
  }
}

// elementUI table 添加完全填充单元格
.el-table {
  td {
    position: relative;

    .cell {
      .fullCell {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
      }
    }
  }

  &.paddingRemove {
    td {
      padding: 0 !important;
    }
  }
}

// 合并账单表格内识别换行
.mergeStatementTable {
  .cell {
    white-space: pre-line !important;
    padding: 0px !important;
  }
}

// printPdfDialog组件 样式
.print_pdf_dialog {
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
  }

  .el-table__cell {
    padding: 0;
    height: auto;
  }
}

.editOrderModal_container .editOrderModal {
  // height: calc();
  overflow: auto;
  // padding-bottom: 20px;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  // margin-bottom: 200px;
}

// fixedDialog组件中的padding取消效果
.el-dialog__wrapper.paddingCancel {
  .el-dialog__body {
    padding: 0px !important;
  }
}

// 同意申请  - 订单取消
.agreeToApply {
  background: #fff !important;
  border: 1px solid #dcdfe6 !important;
  color: #606266 !important;

  &:hover {
    color: #409eff !important;
    border-color: #c6e2ff !important;
    background-color: #ecf5ff !important;
  }
}

//  菜单栏收起时 子菜单选项溢出屏幕问题
body > .el-menu--vertical {
  max-height: 100vh;
  overflow: auto;
}

// 供应商资料里营运港口下拉框
.select_port_popper .el-select-dropdown__item {
  padding-right: 10px !important;
}

// 供应商资料 联系方式pop 上传qq和微信群图片 会溢出的问题
.qqAndWechatPop {
  height: 810px;
  max-height: 95vh;
  overflow: auto;
}
</style>
